@import "scss/typo-from-loyalis.scss";
h1,
h2,
h3,
h4,
h5,
h6 {
  @include fontsize-title;
}

$red: rgb(255, 0, 0);
$yellow: rgb(255, 255, 0);
$green: rgb(0, 255, 0);
$blue: #00a7e7;
$white: rgb(255, 255, 255);
$orange: #ee7203;
$pink: #e0318a;
$darkblue: #0056a4;
$grey: #9b9b9d;
$background: #e8f7fd;

// new colors
$blue-zakelijk: #0057a3;
$vitality: #ff8f8a;
$prevention: #8fc4c0;
$sureness: #e8ba3c;
$absenteeism: #9daaf2;
$reintegration: #afc75b;

/* Loyalis */
$f: $blue-zakelijk;
$r: $vitality;
$u: $prevention;
$d: $sureness;
$l: $absenteeism;
$b: $reintegration;

/* KV */
// $f: $green;
// $r: $yellow;
// $u: $pink;
// $d: $blue-zakelijk;
// $l: purple;
// $b: $darkblue;

$break-small: 48em; // as from loyalis css
$break-medium: 87.5em; // as from loyalis css

$break-small: 768px;
$break-medium: 1024px;
$break-large: 1440px;
$break-xl-large: 2000px;

$breakpoints: (
  small: "screen and (min-width: " + $break-small + ")",
  // as from NEW loyalis css
  medium: "screen and (min-width: " + $break-medium + ")",
  // as from NEW loyalis css
  large: "screen and (min-width: " + $break-large + ")",
  // as from NEW loyalis css
  x-large: "screen and (min-width: " + $break-xl-large + ")"
    // as from NEW loyalis css,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

@mixin mq($mq) {
  $breakpoint-found: false;

  /** Loop through the list of breakpoints we’ve provided in our settings file. */
  @each $breakpoint in $breakpoints {
    /** Grab the alias and the condition from their respective locations in */
    /** the list. */
    $alias: nth($breakpoint, 1);
    $condition: nth($breakpoint, 2);

    /** If the media query we’ve specified has an alias and a condition... */
    @if $mq == $alias and $condition {
      /** ...tell the mixin that we’ve found it... */
      $breakpoint-found: true;

      /** ...and spit it out here. */
      @media #{$condition} {
        @content;
      }
    }
  }

  /** If the user specifies a non-exitent alias, send them a warning. */
  @if $breakpoint-found == false {
    @error "Oops! breakpoint ‘#{$mq}’ does not exist.";
  }
}
/* GRID */
:root {
  --column-size: (100vw / 26);
  @include mq(large) {
    --column-size: (1440px / 26);
  }
}

$column-size: var(--column-size);
@import "scss/navigation.scss";
@import "scss/footer.scss";
@import "scss/button.scss";

.container {
  @include mq(large) {
    margin-left: auto;
    margin-right: auto;
    width: $break-large;
  }

  &--grid {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    @include mq(small) {
      display: grid;
      grid-template-columns: repeat(26, 1fr);
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--grid-medium {
    padding-left: 2rem;
    padding-right: 2rem;
    @include mq(medium) {
      display: grid;
      grid-template-columns: repeat(26, 1fr);
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--no-padding {
    padding: 0;
  }
}

$red-light: rgb(247, 195, 195);
$yellow-light: rgb(243, 243, 182);
$green-light: rgb(199, 253, 197);
$blue-light: rgb(210, 214, 248);
$white-light: rgb(160, 159, 159);
$blue-zakelijk-light: rgb(236, 197, 164);

@keyframes emptyCubie {
  0% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.85;
  }
}
@keyframes activeCubieBackground {
  0% {
    opacity: 0.2;
    transform: scale(1);
    border-radius: 0em;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
    border-radius: 0.1em;
  }
  100% {
    opacity: 0.2;
    transform: scale(1);
    border-radius: 0em;
  }
}

@keyframes fadeOutCube {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

* {
  box-sizing: border-box;
}

html.local,
body.local {
  min-height: 50vh;
  height: 100%;

  @include fontsize-bodytext;
  color: $blue-zakelijk;
}

$cubesmall: 180px;
$cubesize: 220px;

#kubus,
.app {
  padding-top: 0 !important;
  // background: #e8f7fd;

  // height: 100vh;
  min-height: 600px;
  overflow: visible;
  position: relative;
  // background: $background;
  margin-left: 0;

  @include mq("small") {
    margin-top: 5rem;
    grid-column: 2/23;
    min-height: 600px;
  }
}
.app {
  &.user-is-coloring {
    .cubecontainer {
      cursor: none;
    }
  }

  .appContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    margin-top: 8rem;
    @include mq("small") {
      flex-direction: row;
      height: 100%;
      margin-top: 0;
    }
  }
}
.title {
  @include fontsize-hero-title;
  color: $blue-zakelijk;
  &--bold {
    @include fontsize-hero-title-bold;
  }
}
.colorPallete {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;

  > div {
    cursor: default;
    position: absolute;
    left: 0;
    bottom: 0;

    z-index: 10;

    padding: 0.5em;
    display: flex;

    justify-content: center;
    background: transparent;
    width: 100%;

    @include mq("small") {
      width: 50%;
      bottom: 1em;

      transform: translateX(50%);
    }
  }

  .color {
    display: inline-block;
    position: relative;

    transition: 0.2s;
    transform-origin: center;
    border-radius: 50%;
    border: 2px solid #333;
    width: 2em;
    height: 2em;
    margin: 0 0.2em;

    &.F {
      background: $f;
    }
    &.L {
      background: $l;
    }
    &.R {
      background: $r;
    }
    &.U {
      background: $u;
    }
    &.D {
      background: $d;
    }
    &.B {
      background: $b;
    }
    &:hover {
      transform: scale(1.05);
    }
    &.selected {
      transform: scale(1.25);
    }
  }
  .indicator {
    display: none;
    position: absolute;
    top: 2rem;
    right: -1rem;
    height: 2rem;
    width: 2rem;
    background: url("img/color_indicator.svg");
    background-size: contain;
    // background-position: center right;
    background-repeat: no-repeat;
    transition: all 0.2s;
    transform: rotate(180deg);
    &.select-1 {
      top: 2rem;
    }
    &.select-2 {
      top: 5rem;
    }
    &.select-3 {
      top: 8rem;
    }
    &.select-4 {
      top: 11rem;
    }
    &.select-5 {
      top: 14rem;
    }
    &.select-6 {
      top: 17rem;
    }
  }
}

.cubecontainer {
  position: relative;
  height: 100%;
  z-index: 1;
  @media (min-width: $break-small) {
    width: 50%;
  }

  // position: absolute;
  // top: 0;
  // left: 0;

  // height: 50%;
  // width: 50%;
  // align-items: center;
  // transform: translateX(50%) translateY(25%);
  // transition: 0.5s;
  // z-index: 6;
  &.position-left {
    transform: translateX(0%) translateY(10%);
  }
  .cubecontrols {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 100;
    @media (min-width: $break-small) {
      right: auto;
      left: calc(50% + #{$cubesize});
    }

    > div {
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s ease;

      &:hover {
        transform: scale(1.3);
      }
      svg {
        width: 2em;
        cursor: default;
        path {
          fill: #73a8d6;
        }
      }

      &.up {
        left: 0;
        margin-bottom: 2em;
      }
      &.down {
        left: 0;
        margin-top: 2em;
      }
      &.right {
        left: $cubesize * 0.8;
        top: 0;
      }
      &.left {
        left: $cubesize * -1;
        transform: scale(2) translateY(-5%) rotate(180deg);
        top: 0;
        i {
          // transform: rotate(180deg);
        }
      }
    }
    &.hide {
      display: none;
    }
    &:hover {
      // opacity: 1;
    }
  }

  .scenecontainer {
    width: 100%;
    height: 100%;
    position: relative;
    .backgroundCircle {
      display: block;
      background: #fff;
      opacity: 0.7;
      width: 422px;
      height: 422px;
      position: absolute;
      border-radius: 50%;
      top: calc(50% - 211px);
      left: calc(50% - 211px);
    }
  }
  .scene {
    position: absolute;
    top: calc(50% - #{$cubesmall} / 2);
    left: calc(50% - #{$cubesmall} / 2);
    width: $cubesmall;
    height: $cubesmall;
    @include mq("small") {
      top: calc(50% - #{$cubesize} / 2);
      left: calc(50% - #{$cubesize} / 2);
      width: $cubesize;
      height: $cubesize;
    }
    perspective: 1000px;
    transition: 0.2s;
    &.hideCube {
      opacity: 0;
    }

    .cube {
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      // transition: transform 1s;
      // transform: translateZ(-50px) rotateY(20deg);

      .stepExplainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: url("img/icon_rotate.svg");
        transform-origin: center;

        transition: all 0.6s;
        opacity: 0;
        pointer-events: none;
        // &.direction-left {
        //   background: url("img/icon_rotate_left.svg");
        //   &.repeat-2 {
        //     background: url("img/icon_rotate_left-2x.svg");
        //   }
        // }
        // &.repeat-2 {
        //   background: url("img/icon_rotate-2x.svg");
        // }
        &.visible {
          opacity: 1;
        }
        &.right {
          transform: rotateY(90deg) translateZ($cubesmall/1.5);
          &.visible {
            transform: rotateY(90deg) translateZ($cubesmall/1.2);
          }
        }
        &.front {
          transform: rotateY(0deg) translateZ($cubesmall/1.5);
          &.visible {
            transform: rotateY(0deg) translateZ($cubesmall/1.2);
          }
        }

        &.back {
          transform: rotateY(180deg) translateZ($cubesmall/1.2);
          &.visible {
            transform: rotateY(180deg) translateZ($cubesmall * 1.5);
          }
        }
        &.left {
          transform: rotateY(-90deg) translateZ($cubesmall/1.2);
          &.visible {
            transform: rotateY(-90deg) translateZ($cubesmall * 1.5);
          }
        }
        &.up {
          transform: rotateX(90deg) translateZ($cubesmall/1.5);
          &.visible {
            transform: rotateX(90deg) translateZ($cubesmall/1.2);
          }
        }
        &.down {
          transform: rotateX(-90deg) translateZ($cubesmall/1.2);
          &.visible {
            transform: rotateX(-90deg) translateZ($cubesmall * 1.4);
          }
        }
        @include mq("small") {
          &.right {
            transform: rotateY(90deg) translateZ($cubesize/1.5);
            &.visible {
              transform: rotateY(90deg) translateZ($cubesize/1.2);
            }
          }
          &.front {
            transform: rotateY(0deg) translateZ($cubesize/1.5);
            &.visible {
              transform: rotateY(0deg) translateZ($cubesize/1.2);
            }
          }

          &.back {
            transform: rotateY(180deg) translateZ($cubesize/1.2);
            &.visible {
              transform: rotateY(180deg) translateZ($cubesize * 1.5);
            }
          }
          &.left {
            transform: rotateY(-90deg) translateZ($cubesize/1.2);
            &.visible {
              transform: rotateY(-90deg) translateZ($cubesize * 1.5);
            }
          }
          &.up {
            transform: rotateX(90deg) translateZ($cubesize/1.5);
            &.visible {
              transform: rotateX(90deg) translateZ($cubesize/1.2);
            }
          }
          &.down {
            transform: rotateX(-90deg) translateZ($cubesize/1.2);
            &.visible {
              transform: rotateX(-90deg) translateZ($cubesize * 1.4);
            }
          }
        }
      }
      &.editable {
        .cubie {
          &:hover {
            &:not(.middle-cubie) {
              border: 2px solid #fff;
            }
          }
        }
      }
      .cube_face {
        position: absolute;
        width: $cubesmall;
        height: $cubesmall;
        @include mq("small") {
          width: $cubesize;
          height: $cubesize;
        }

        font-size: 2rem;
        color: #fff;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 5px;
        text-transform: uppercase;
        // border: 2px solid #000;
        background-color: rgba(#000, 0.8);

        border-radius: 0.2em;
        padding: 0.1em;
        // backface-visibility: hidden;
        transition: opacity 0.2s;
        .cubie {
          user-select: none;
          border: 2px solid #000;
          border-radius: 0.2em;
          // background-color: hsla(0, 100%, 50%, 1);
          transition: all 0.2s;
          // overflow: hidden;
          font-size: 0.3em;
          color: #000;
          position: relative;
          background: rgb(80, 80, 80);
          background: #c0f1f1;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            width: 100%;
            height: 100%;
            background-color: #fff;
            font-size: 3rem;
            color: #000;
            text-align: center;
          }
          &:after {
            content: "";
            transition: background 0.2s;

            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.7);
            z-index: 2;
          }
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            transition: 1s;
            transform-origin: center;
            opacity: 0;
            z-index: 1;
            pointer-events: none;
          }
          &.F {
            &:after {
              background: $f;
            }
          }
          &.L {
            &:after {
              background: $l;
            }
          }
          &.R {
            &:after {
              background: $r;
            }
          }
          &.U {
            &:after {
              background: $u;
            }
          }
          &.D {
            &:after {
              background: $d;
            }
          }
          &.B {
            &:after {
              background: $b;
            }
          }
          &.empty {
            animation-name: emptyCubie;
            animation-iteration-count: infinite;
            animation-duration: 4s;
            background: rgb(249, 249, 249);
            background: #c0f1f1;
            // &:after {

            // }
          }

          &.active {
            transition: 1s;
            opacity: 1;
            transform: scale(1.05);
            z-index: 5;
            &:before {
              animation-name: activeCubieBackground;
              animation-iteration-count: infinite;
              animation-duration: 1.6s;
              z-index: 10;
            }
            &:after {
              z-index: 100;
            }
          }
        }
        &.face-front {
          transform: rotateY(0deg) translateZ($cubesmall/2);
        }
        &.face-right {
          transform: rotateY(90deg) translateZ($cubesmall/2);
        }
        &.face-back {
          transform: rotateY(180deg) translateZ($cubesmall/2);
        }
        &.face-left {
          transform: rotateY(-90deg) translateZ($cubesmall/2);
        }
        &.face-up {
          transform: rotateX(90deg) translateZ($cubesmall/2);
        }
        &.face-down {
          transform: rotateX(-90deg) translateZ($cubesmall/2);
        }
        @include mq("small") {
          &.face-front {
            transform: rotateY(0deg) translateZ($cubesize/2);
          }
          &.face-right {
            transform: rotateY(90deg) translateZ($cubesize/2);
          }
          &.face-back {
            transform: rotateY(180deg) translateZ($cubesize/2);
          }
          &.face-left {
            transform: rotateY(-90deg) translateZ($cubesize/2);
          }
          &.face-up {
            transform: rotateX(90deg) translateZ($cubesize/2);
          }
          &.face-down {
            transform: rotateX(-90deg) translateZ($cubesize/2);
          }
        }
      }
      &.has-highlighted-face {
        .cubie {
          &:before {
            background-color: rgb(17, 17, 43);
            opacity: 1;
          }
          &:after {
            opacity: 0.2;
          }
        }
        &.highlighted-front {
          .face-front {
            .cubie {
              &:after {
                opacity: 1;
              }
            }
          }
          .face-right {
            .cubie {
              &:nth-of-type(3n-2) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-up {
            .cubie {
              &:nth-of-type(7),
              &:nth-of-type(8),
              &:nth-of-type(9) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-down {
            .cubie {
              &:nth-of-type(1),
              &:nth-of-type(2),
              &:nth-of-type(3) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-left {
            .cubie {
              &:nth-of-type(3n) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
        &.highlighted-right {
          .face-right {
            .cubie {
              &:after {
                opacity: 1;
              }
            }
          }
          .face-front {
            .cubie {
              &:nth-of-type(3n) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-up {
            .cubie {
              &:nth-of-type(3n) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-back {
            .cubie {
              &:nth-of-type(3n-2) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-down {
            .cubie {
              &:nth-of-type(3n) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.highlighted-left {
          .face-left {
            .cubie {
              &:after {
                opacity: 1;
              }
            }
          }
          .face-front {
            .cubie {
              &:nth-of-type(3n -2) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-up {
            .cubie {
              &:nth-of-type(3n - 2) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-back {
            .cubie {
              &:nth-of-type(3n) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-down {
            .cubie {
              &:nth-of-type(3n-2) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.highlighted-up {
          .face-up {
            .cubie {
              &:after {
                opacity: 1;
              }
            }
          }
          .face-front,
          .face-right,
          .face-left,
          .face-back {
            .cubie {
              &:nth-of-type(1),
              &:nth-of-type(2),
              &:nth-of-type(3) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
        &.highlighted-down {
          .face-down {
            .cubie {
              &:after {
                opacity: 1;
              }
            }
          }
          .face-front,
          .face-right,
          .face-left,
          .face-back {
            .cubie {
              &:nth-of-type(7),
              &:nth-of-type(8),
              &:nth-of-type(9) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.highlighted-back {
          .face-back {
            .cubie {
              &:after {
                opacity: 1;
              }
            }
          }
          .face-left {
            .cubie {
              &:nth-of-type(3n-2) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-up {
            .cubie {
              &:nth-of-type(1),
              &:nth-of-type(2),
              &:nth-of-type(3) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-right {
            .cubie {
              &:nth-of-type(3n) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
          .face-down {
            .cubie {
              &:nth-of-type(7),
              &:nth-of-type(8),
              &:nth-of-type(9) {
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
        // .cube__face {
        //   &.cube__face--top {
        //     .cubie {
        //       background: $blue-light;
        //     }
        //   }
        //   &.cube__face--front {
        //     .cubie {
        //       background: $red-light;
        //     }
        //   }
        //   &.cube__face--right {
        //     .cubie {
        //       background: $yellow-light;
        //     }
        //   }
        // }
        // &.highlighted-front {
        //   .cube__face {
        //     &.cube__face--front {
        //       .cubie {
        //         background: $red;
        //       }
        //     }
        //     &.cube__face--top {
        //       .cubie {
        //         &:nth-of-type(7),
        //         &:nth-of-type(8),
        //         &:nth-of-type(9) {
        //           background: $blue;
        //         }
        //       }
        //     }
        //     &.cube__face--right {
        //       .cubie {
        //         &:nth-of-type(3n + 1) {
        //           background: $yellow;
        //         }
        //       }
        //     }
        //   }
        // }
        // &.highlighted-right {
        //   .cube__face {
        //     &.cube__face--right {
        //       .cubie {
        //         background: $yellow;
        //       }
        //     }
        //     &.cube__face--top {
        //       .cubie {
        //         &:nth-of-type(3n) {
        //           background: $blue;
        //         }
        //       }
        //     }
        //     &.cube__face--front {
        //       .cubie {
        //         &:nth-of-type(3n) {
        //           background: $red;
        //         }
        //       }
        //     }
        //   }
        // }
        // &.highlighted-top {
        //   .cube__face {
        //     &.cube__face--top {
        //       .cubie {
        //         background: $blue;
        //       }
        //     }
        //     &.cube__face--right {
        //       .cubie {
        //         &:nth-of-type(1),
        //         &:nth-of-type(2),
        //         &:nth-of-type(3) {
        //           background: $yellow;
        //         }
        //       }
        //     }
        //     &.cube__face--front {
        //       .cubie {
        //         &:nth-of-type(1),
        //         &:nth-of-type(2),
        //         &:nth-of-type(3) {
        //           background: $red;
        //         }
        //       }
        //     }
        //   }
        // }
      }

      &.show-front {
        transform: translateZ(200px) rotateY(0deg);
      }
      &.show-right {
        transform: translateZ(-1 * $cubesize / 2) rotateY(-90deg);
      }
      &.show-back {
        transform: translateZ(-1 * $cubesize / 2) rotateY(-180deg);
      }
      &.show-left {
        transform: translateZ(-1 * $cubesize / 2) rotateY(90deg);
      }
      &.show-up {
        transform: translateZ(-1 * $cubesize / 2) rotateX(-90deg);
      }
      &.show-bottom {
        transform: translateZ(-1 * $cubesize / 2) rotateX(90deg);
      }
      &.show-random1 {
        transform: translateZ(100px) rotateX(-20deg) rotateY(-45deg);
        // transform: translateZ(100px) rotateX(-160deg) rotateY(195deg);
      }
      &.show-random2 {
        transform: translateZ(100px) rotateX(20deg) rotateY(-225deg);
      }

      &.solved {
        animation: fadeOutCube 0.3s ease;
      }
    }
  }
}

.debug-controls {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3em;
  background: #fff;
  padding: 1em 3em;
  border-radius: 0.2em;
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 0.8em;
  z-index: 10;
  opacity: 0;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
  cursor: default;
  strong {
    margin-right: 1em;
  }
  a {
    text-transform: lowercase;
    font-family: "Arial";
    text-decoration: none;
    color: #000;
    margin: 0 1em;
    border-bottom: 1px solid #000;
  }
  .solverInit {
    justify-self: flex-end;
    &:before {
      content: "";
      display: inline-block;
      border-radius: 50%;
      height: 1em;
      width: 1em;
      background-color: #fff;
      border: 1px solid #000;
      margin-right: 0.5em;
      vertical-align: middle;
      background: $blue-zakelijk;
    }
    &.ready {
      &:before {
        background: rgb(0, 255, 0);
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-align: center;
}
.error {
  position: fixed;
  top: 1em;
  right: 1em;

  width: 40%;
  background: white;
  padding: 1em;
  // flex: 0 0 100px;
  // position: relative;
  border-radius: 10px;
  box-shadow: 5px 10px 18px #888888;
}

.cursorContainer {
  display: none;
  @include mq("small") {
    display: block;
  }
  position: fixed;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  pointer-events: none;
  z-index: 6;
  cursor: none;
  .cursor {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
    border: 4px solid #000;
    &.F {
      border-color: $f;
    }
    &.L {
      border-color: $l;
    }
    &.R {
      border-color: $r;
    }
    &.U {
      border-color: $u;
    }
    &.D {
      border-color: $d;
    }
    &.B {
      border-color: $b;
    }
    &.transparent {
      opacity: 0;
    }
  }
}

.notifications {
  position: fixed;
  top: 1em;
  right: 1em;

  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-end;
  z-index: 10;
  .notification {
    width: 40vw;
    padding: 1em 1em 1em 2em;

    background: white;
    margin: 10px;
    flex: 0 0 100px;
    position: relative;
    box-shadow: 0 0.16rem 0.36rem 0 rgba(0, 0, 0, 0.13),
      0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.11);
    border-radius: 0.4rem;

    h1 {
      margin: 0.2em 0;
      font-size: 1.8em;
    }
    i {
      text-decoration: underline;
      font-style: normal;
    }
    p {
      color: #605e5c;
    }
    &.warning {
    }
    &.error {
    }
  }
}

.slideshow {
  display: none;
  @include mq("small") {
    display: flex;
  }
  width: 50%;
  flex-direction: column;
  justify-content: center;
  .slide {
    height: 100%;

    overflow: hidden;
    padding-right: 1em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.444rem * 0.9;
    @include mq("medium") {
      font-size: 1rem;
      line-height: 1.444rem;
    }
    .imageContainer {
      width: 15em;
      height: 15em;

      border-radius: 50%;
      overflow: hidden;
      top: 100px;

      left: -50px;
      @include mq("medium") {
        display: block;
        top: calc(50% - 211px);
        left: 0;
      }
      position: absolute;
      z-index: 1;
      .image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }
    }
    .content {
      background: #fff;
      background: $blue-zakelijk;
      color: #fff;
      border-radius: 3px;
      width: 80%;
      padding: 2em 2em 1em 2em;
      top: 5em;
      z-index: 2;
      @include mq("medium") {
        margin-left: 10em;
        top: 5em;
      }
      position: relative;

      > div {
        @include mq("medium") {
          max-width: calc(100% - 4em);
        }
      }
      a {
        text-decoration: none;
        display: block;
        text-align: right;
        padding-right: 2em;
        margin-top: 1em;
        font-weight: 800;
        color: inherit;
        &:hover {
          i {
            transform: translateX(0.5em);
          }
        }

        i {
          font-size: 0.7em;
          display: inline-block;
          margin-left: 0.5em;
          transition: 0.2s;
        }
      }
    }
  }
  .sliderPagination {
    text-align: center;
    margin-bottom: 2em;
    > div {
      display: inline-block;
      width: 1em;
      height: 1em;
      background: #fff;
      cursor: pointer;
      margin: 0 0.5em;
      border-radius: 50%;
      border: 2px solid #fff;
      transition: all 0.2s;

      border: 2px solid #fff;
      background: $blue-zakelijk;
      &:hover {
        border: 2px solid $orange;
        // background: $background;
      }
      &.active {
        border: 2px solid $orange;
        background: $orange;
      }
    }
  }
}

// .slideshowContainer {
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   // transition: background 1s;
//   overflow: hidden;

//   pointer-events: none;
//   @include mq("small"){
//     display: block;
//   }

//   .hiddenSlideImages {
//     img {
//       display: none;
//     }
//   }
//   .slide {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 35%;
//     height: 100%;
//     z-index: 100;
//     .textBackground {
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       width: 100%;
//       height: 50%;
//       z-index: 2;
//       transform-origin: top left;

//       > div {
//         height: 100%;
//         width: 100%;
//       }
//     }
//     .image {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 50%;
//       background-size: cover;
//       background-position: center;
//       // img {
//       //   position: absolute;
//       //   width: 100%;
//       //   height: 50%;
//       //   top: 0;
//       //   left: 0;
//       //   z-index: 1;
//       // }
//     }
//     .info {
//       position: absolute;
//       width: 100%;
//       height: 50%;
//       bottom: 0;
//       left: 1em;
//       z-index: 5;
//       padding: 1em;
//       font-size: 1rem;
//       h1 {
//         color: #fff;
//         margin-bottom: 0.5em;
//         font-size: 1.5em;
//       }
//       h4 {
//         color: #fff;
//         margin-bottom: 1em;
//       }

//       p {
//         color: #fff;
//       }
//       a {
//         display: inline-block;
//         margin-top: 0.5em;
//         // border: 2px solid #fff;
//         // font-weight: bold;
//         // padding: 0.2em 1em;
//         color: #fff;
//         text-decoration: none;
//         pointer-events: all;
//         font-size: 1em;
//         position: relative;
//         &::before {
//           content: "";
//           display: block;
//           position: absolute;
//           bottom: 0;
//           left: 0;
//           width: 100%;
//           height: 1px;

//           background: #fff;
//         }
//         &::after {
//           content: "";
//           display: block;
//           position: absolute;
//           bottom: -1.5px;
//           left: 0;
//           width: 100%;
//           height: 3px;
//           transition: 0.2s;
//           transform-origin: left;
//           transform: scaleX(0);
//           background: #fff;
//         }
//         &:hover {
//           &:after {
//             transform: scaleX(1);
//           }
//         }

//         i {
//           margin-left: 0.5em;
//           font-weight: normal;
//           top: 0.1em;
//           position: relative;
//           transform: scale(0.8);
//           transform-origin: center;
//           display: inline-block;
//         }
//       }
//     }
//   }
// }

// .c-btn {
//   display: inline-block;
//   margin-right: 1em;
//   padding-bottom: 1em;
//   position: relative;
//   user-select: none;
//   @include fontsize-bodytext;

//   > a,
//   > span {
//     @include fontsize-button;
//     align-items: center;
//     color: inherit;
//     cursor: pointer;
//     display: flex;
//     line-height: 1;
//     text-decoration: none;
//     transition: 0.2s ease-out;
//   }

//   .button__label {
//     padding: 0 !important;
//     width: calc(100% - 1.5em);
//   }

//   .button__arrow {
//     margin-left: 0.5em;
//     transform: translate(0%, -5%);
//     transition: 0.2s ease-out;
//     width: 1.5em;
//     path {
//       fill: none;
//       stroke: currentColor;
//     }
//   }

//   .button__icon {
//     // height: 100%;
//     // margin-right: 0.75em;
//     // transform: scale(1.4);
//     // transform-origin: center;
//     // color: inherit;
//     height: 1em;
//     path {
//       fill: none;
//       stroke: currentColor;
//     }
//   }

//   &:hover {
//     a,
//     span {
//       padding-right: 1.2em;
//     }

//     .button__arrow {
//       transform: translate(0.5em, -5%);
//     }
//   }

//   &--small {
//     transform: scale(0.75);
//     transform-origin: center;
//   }

//   &--primary {
//     a,
//     span {
//       background: $orange;
//       border-radius: 1em;
//       color: #fff;
//       padding: 0.55em 1em 0.5em;

//       &.button__label {
//         background: transparent;
//         color: inherit;
//       }
//     }
//   }

//   &--secondary {
//     a,
//     span {
//       background: $blue-zakelijk;
//       border-radius: 1em;
//       color: #fff;
//       padding: 0.55em 1em 0.5em;
//     }
//   }

//   &--tertiary {
//     a,
//     span {
//       background: transparent;
//       border: 2px solid $blue-zakelijk;
//       border-radius: 1em;

//       color: colors(blue-zakelijk);
//       padding: 0.55em 1em 0.5em;

//       &.button__label {
//         border-width: 0;
//       }
//     }
//   }

//   &--quaternary {
//     color: var(--target-audience-color);
//     margin-bottom: 6px;

//     a,
//     span {
//       @include fontsize-button--tertiary;
//     }

//     &::after {
//       background: var(--target-audience-color);
//       bottom: 0.2em;
//       content: "";
//       display: block;
//       height: 2px;
//       left: 0;
//       position: absolute;
//       width: 100%;
//       z-index: 10;
//     }

//     &:hover {
//       a,
//       span {
//         padding-right: 0.5em;
//       }
//     }
//   }
// }
.sceneExplainer {
  position: relative;
  z-index: 10;
  bottom: 0;
  // text-align: center;
  // padding: 1em;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: transparent;
  font-size: 1.14286rem;
  line-height: 1.5;

  @include mq("small") {
    display: flex;
    width: 50%;
  }
  > div:not(.footer) {
    user-select: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h1 {
      margin-bottom: 1.071em;
      margin-top: 0.2em;
      &.thin {
        font-weight: 400;
        margin: 0;
      }
    }
  }

  &.transparent {
    background: transparent;
  }
  .footer {
    // height: 1%;
    max-width: 100vw;
    margin: 1em 0.5em;
    @include mq("small") {
      position: absolute;
      bottom: 1em;
      left: 0.5em;
    }
    // flex-shrink: 10;

    span {
      font-size: 0.8em;

      margin-right: 1em;
      cursor: pointer;
      color: #73a8d6;

      font-size: 1.14286rem;
      font-size: 0.9rem;

      img {
        margin-right: 0.5em;
        width: 1em;
      }
      &:before {
        font-size: 0.6rem;
        line-height: 2.2;
        content: "\f054";
        font-family: FontAwesome;
        font-weight: 400;
        transform: rotate(180deg) translateY(0.2em) translateX(0.4em);
        display: inline-block;
        margin-right: 0.5em;
        transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
  }
}

.placeholder {
  width: 50%;
}
.solutionSteps {
  position: absolute;
  // width: 50%;
  left: 1em;
  bottom: 0;
  right: 0;
  width: calc(100% - 2em);
  @include mq("small") {
    width: 50%;
    left: 25%;
  }

  p {
    margin-bottom: 0;
  }
  .stepButtons {
    display: flex;
    justify-content: center;

    a {
      margin: 2em 1em;
      text-decoration: none;
      width: 100%;
      max-width: 48%;
      width: auto;
      font-size: 1em;
      font-weight: normal;
      @include mq("small") {
        font-size: 0.8em;
        max-width: none;
        margin: 2em 0.2em;
      }
      @include mq("medium") {
        font-size: 1em;
        max-width: 48%;
      }

      line-height: 1;
      i {
        margin-left: 1em;
        top: 0.1em;
        position: relative;
      }
      &.previous {
        text-align: right;
        i {
          display: inline-block;
          margin-left: 0;
          margin-right: 1em;
          transform: rotate(180deg) translateY(10%);
        }
      }
    }
  }
}
.solvedMessage {
  // position: absolute;
  // top: 2em;
  // left: 2em;
  // width: calc(100% - 4em);
  // height: calc(100% - 4em);
  // background: #fff;
  z-index: 100;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: middle;
  flex-direction: column;
  @include mq("small") {
    flex-direction: row;
  }
  > div {
    order: 10;
    padding-right: 2em;
    &.videoImage {
      order: 1;
      padding: 2em;

      align-items: center;
      .image {
        width: 30em;
        height: 30em;
        border-radius: 50%;
        background-position: center;
        background-size: cover;

        img {
          border-radius: 50%;
        }
      }
    }
    @include mq("small") {
      width: 50%;
      &.videoImage {
        order: 20;
      }
    }

    // height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    iframe {
      max-width: 100%;
    }
  }
  h1 {
    margin-bottom: 0.5em;

    &.thin {
      font-weight: 400;
    }
  }
  h2 {
    margin: 1em 0 0.2em 0;
    color: $blue-zakelijk;
    font-size: 1.2em;
  }
  address {
    font-style: normal;
    margin: 0;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-style: italic;
    line-height: 1.2;
    display: block;
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    width: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .restart {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    height: 1%;
    margin-bottom: 1em;
    // flex-shrink: 10;

    margin-right: 1em;
    cursor: pointer;
    color: #4dbeee;
    font-size: 0.7rem;

    img {
      margin-right: 0.5em;
      width: 1em;
    }
    &:before {
      font-size: 0.71429rem;
      line-height: 2.2;
      content: "\f054";
      font-family: FontAwesome;
      font-weight: 400;
      transform: rotate(180deg) translateY(0.2em) translateX(0.4em);
      display: inline-block;
      margin-right: 0.5em;
      transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
}

/* DEPENING ON ENVIRONMENT */
// body.local .container {
//   max-width: 80%;
//   margin-left: 10%;
//   margin-top: 10em;
// }
// #kubus-container {
//   padding-top: 0 !important;
// }
