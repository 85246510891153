.navigation {
  --submenu-background-height: 0;
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  padding: calc(#{$column-size} * 2);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
  pointer-events: none;
  @include mq(medium) {
    background: #fff;
    display: block;
    font-size: 1rem;
    height: auto;
    padding: 0;
    position: fixed;
    pointer-events: all;
  }

  &--open {
    position: fixed;
  }

  &__main-navigation {
    padding: 0;
    width: 100%;
    z-index: 1;

    @include mq(medium) {
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
      padding: 2rem 0;
      position: relative;
    }
  }

  &__logo {
    left: 2rem;
    position: absolute;
    top: 2rem;
    pointer-events: all;

    @include mq(medium) {
      grid-column: 2/5;
      position: static;
    }
  }

  &__item {
    color: colors(secondary);
    text-align: right;

    @include mq(medium) {
      //   @include pipe-separated-list(0);

      &:hover {
        .navigation__link {
          transform: translateY(0.2em);
        }
      }
    }

    .tooltip {
      align-items: flex-start;

      bottom: -2.2rem;
      color: #fff;
      display: flex;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 85%);
    }
  }

  &__link {
    color: inherit;
    display: inline-block;
    padding: 1em 0;
    position: relative;
    text-align: right;
    text-decoration: none;
    text-decoration: none;
    transition: 0.2s;
    user-select: none;
    @include mq(medium) {
      padding: 1em 0.8em;
    }

    &-active {
      font-weight: 700;
    }

    &-portal {
      align-items: center;
      color: colors(grey);
      display: flex;
      justify-content: flex-end;
      padding: 1em 0;
      text-align: right;

      :deep(svg) {
        height: 1em;
        margin-right: 0.5em;
        position: relative;
        top: -0.1em;
        width: auto;
      }
    }
  }

  &__linktitle {
    display: inline-block;
    transition: 0.2s;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    @include mq(medium) {
      align-items: center;
      flex-direction: row;
    }

    &-main {
      @include mq(medium) {
        grid-column: 5/22;
        padding-left: 2rem;
      }
    }

    &-portal {
      @include mq(medium) {
        grid-column: 22/26;
        grid-row: 1/1;
        justify-content: flex-end;
      }
    }
  }

  &__menu_toggle {
    color: var(--target-audience-color);
    display: block;
    pointer-events: all;
    position: fixed;
    right: 2rem;
    top: 3.2rem;
    z-index: 15;

    @include mq(medium) {
      display: none;
    }
  }

  &--compact {
    :deep(.submenu) {
      @include mq(medium) {
        top: calc(100% - 1.5rem);
      }
    }

    .navigation__item {
      .tooltip {
        bottom: -0.75em;
      }
    }
  }

  &__submenu_background {
    display: none;
    @include mq(medium) {
      background: #c5e0f2;

      display: block;
      height: 200vh;
      left: 0;

      pointer-events: none;
      pointer-events: none;
      position: absolute;
      top: -200vh;
      transform: translateY(var(--submenu-background-height));
      transition: 0.5s;
      width: 100%;
      z-index: 0;
    }
  }

  &--closed {
    pointer-events: none;
    @include mq(medium) {
      pointer-events: all;
    }
  }
}

.topNavigation {
  @include fontsize-navigation-topbar;
  background: $blue-zakelijk;
  color: #fff;
  display: none;
  position: relative;

  @include mq(medium) {
    display: block;
  }

  &--hidden {
    transform: translateY(calc(-100% + 2px));
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 1.6em calc(#{$column-size}) 1em 0;

    a {
      color: #a3caeb;
      text-decoration: none;
      user-select: none;

      span {
        color: #fff;
      }
    }
  }
  .targetaudience_switch {
    position: relative;
    margin: 0;
    padding: 0;

    &__popupbase {
      position: absolute;

      :deep(.guide_popup) {
        width: 475px;
      }
    }

    &__item {
      transition: 0.2s;

      &:hover {
        color: #fff;
      }
    }
  }

  a {
    padding: 0 0.2em;
    position: relative;
    &::after {
      content: "|";
      display: inline-block;
      font-weight: normal;
      margin: 0 1em;
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }

  .tooltip {
    bottom: -1rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: calc(50% - 1em);
    pointer-events: none;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 5%);
    transition: 0.5s;
    width: 2rem;

    &--hidden {
      transform: translate(-50%, 105%);
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }

  a {
    &:last-of-type {
      .tooltip {
        left: 50%;
      }
    }
  }
}
