.logo-footer {
  padding: 3em 15% 3em 0;

  &__logo {
    margin-top: 1em;
    text-align: right;

    svg {
      width: 6rem;
    }
  }
  .loyalis-line {
    max-width: 100%;
  }
}
