.button {
  display: inline-block;
  margin-right: 1em;
  padding-bottom: 1em;
  position: relative;
  user-select: none;

  > a,
  > span {
    @include fontsize-button;
    align-items: center;
    color: inherit;
    cursor: pointer;
    display: flex;
    line-height: 1;
    text-decoration: none;
    transition: 0.2s ease-out;
  }

  &__label {
    padding: 0 !important;
    width: calc(100% - 1.5em);
  }

  .button__arrow {
    margin-left: 0.5em;
    transform: translate(0%, -5%);
    transition: 0.2s ease-out;
    width: 1.5em;
    path {
      fill: none;
      stroke: currentColor;
    }
  }

  .button__icon {
    height: 100%;
    margin-right: 0.75em;
    transform: scale(1.4);
    transform-origin: center;
  }

  &:hover {
    a,
    span {
      padding-right: 1.2em;
    }

    .button__arrow {
      transform: translate(0.5em, -5%);
    }
  }

  &--small {
    transform: scale(0.75);
    transform-origin: center;
  }

  &--primary {
    a,
    span {
      background: $orange;
      border-radius: 1em;
      color: #fff;
      padding: 0.55em 1em 0.5em;

      &.button__label {
        background: transparent;
        color: inherit;
      }
    }
  }
  &--primary-disabled {
    a,
    span {
      opacity: 0.5;
      cursor: default;
      background: $orange;
      border-radius: 1em;
      color: #fff;
      padding: 0.55em 1em 0.5em;

      &.button__label {
        background: transparent;
        color: inherit;
      }
      .button__arrow {
        opacity: 0;
      }
    }
  }

  &--secondary {
    a,
    span {
      background: $blue-zakelijk;
      border-radius: 1em;
      color: #fff;
      padding: 0.55em 1em 0.5em;
    }
  }

  &--tertiary {
    a,
    span {
      background: transparent;
      border: 2px solid $blue-zakelijk;
      border-radius: 1em;

      color: $blue-zakelijk;
      padding: 0.55em 1em 0.5em;

      &.button__label {
        border-width: 0;
      }
    }
  }
  &--tertiary-back {
    a,
    span {
      background: transparent;
      border: 2px solid $blue-zakelijk;
      border-radius: 1em;

      color: $blue-zakelijk;
      padding: 0.55em 1em 0.5em;

      &.button__label {
        border-width: 0;
      }
    }
    > span {
      flex-direction: row-reverse;
    }
    .button__arrow {
      transform: rotate(180deg);
      margin-left: auto;
      margin-right: 0.5em;
    }
    &:hover {
      a,
      span {
        padding-right: 1em;
        padding-left: 1.5em;
      }

      .button__arrow {
        transform: translate(-0.5em, 0%) rotate(180deg);
      }
    }
  }
  &--tertiary-back-disabled {
    opacity: 0.5;
    a,
    span {
      background: transparent;
      border: 2px solid $blue-zakelijk;
      border-radius: 1em;

      color: $blue-zakelijk;
      padding: 0.55em 1em 0.5em;

      &.button__label {
        border-width: 0;
      }
    }

    > span {
      flex-direction: row-reverse;
    }
    .button__arrow {
      transform: rotate(180deg);
      margin-left: auto;
      margin-right: 0.5em;
    }
    &:hover {
      a,
      span {
        padding-right: 1em;
        padding-left: 1.5em;
      }

      .button__arrow {
        transform: translate(-0.5em, 0%) rotate(180deg);
      }
    }
  }

  &--quaternary {
    color: var(--target-audience-color);
    margin-bottom: 6px;

    a,
    span {
      @include fontsize-button--tertiary;
    }

    &::after {
      background: var(--target-audience-color);
      bottom: 0.2em;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 10;
    }

    &:hover {
      a,
      span {
        padding-right: 0.5em;
      }
    }
  }
}
