$base-font-size: 17px;

@function rem($font-size) {
  @if (type-of($font-size) == number) {
    @if (unit($font-size) != "px") {
      @error "`#{$font-size}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$font-size}` needs to be a number.";
  }

  @return $font-size/ $base-font-size * 1rem;
}

@mixin fontsize-bodytext {
  @include avenir-regular;
  font-size: $base-font-size;
  font-size: clamp(14px, 1.4vw, $base-font-size);
  line-height: 1.4;
}
@mixin fontsize-breadcrumb {
  font-size: rem(17px);
}

@mixin fontsize-title {
  @include avenir-bold;
  font-size: rem(29px);
  line-height: 1.3;
  margin-bottom: 0.5em;

  span {
    @include fontsize-subtitle;
  }
}
@mixin fontsize-bodytext--smaller {
  font-size: rem(14px);
}

@mixin fontsize-title--smaller {
  @include avenir-bold;
  font-size: rem(18px);
  line-height: 1;
}

@mixin fontsize-form--input {
  @include avenir-regular;
  font-size: 0.85em;
}

@mixin fontsize-hero-title {
  @include avenir-regular;
  font-size: rem(41px);
  line-height: 1.3;

  span {
    @include fontsize-subtitle;
  }

  strong,
  b {
    @include avenir-bold;
  }
}

@mixin fontsize-navigation-topbar {
  @include avenir-regular;
  font-size: 0.82rem;
}

@mixin fontsize-hero-title-bold {
  @include avenir-bold;
}

@mixin fontsize-hero-subtitle {
  @include avenir-regular;
  font-size: rem(22px);
  line-height: 1.3;
}

@mixin fontsize-hero-home-after-title {
  @include avenir-regular;
  font-size: rem(33px);
  line-height: 1.3;

  strong,
  b {
    @include avenir-bold;
  }
}

@mixin fontsize-hero-article-subtitle {
  @include avenir-regular;
  font-size: rem(22px);
  line-height: 1.3;
}

@mixin fontsize-themesteaser-item-title {
  @include avenir-bold;
  font-size: rem(20px);
  line-height: 1.3;
}

@mixin fontsize-themesteaser-item-readmore {
  @include avenir-regular;
  font-size: rem(11px);
  line-height: 1;
  text-transform: uppercase;
}

@mixin fontsize-lint-title {
  @include avenir-bold;
  font-size: rem(30px);
  line-height: 1.3;
}

@mixin fontsize-faq-header {
  @include avenir-regular;
  font-size: rem(23px);
  line-height: 1.3;
}

@mixin fontsize-guideslider-title {
  @include avenir-medium;
  font-size: rem(25px);
}
@mixin fontsize-guideslidersmall-title {
  @include avenir-medium;
  font-size: rem(20px);
}

@mixin fontsize-guideslider-role {
  @include avenir-regular;
  font-size: rem(19px);
}
@mixin fontsize-guideslidersmall-role {
  @include avenir-regular;
  font-size: rem(17px);
}
@mixin fontsize-guidepopup-title {
  @include avenir-regular;
  font-size: rem(34px);
  line-height: 1.2;
}
@mixin fontsize-guidepopup-title-bold {
  @include avenir-bold;
  font-size: rem(34px);
}
@mixin fontsize-guidepopup-subtitle {
  @include avenir-regular();
  font-size: rem(22px);
}
@mixin fontsize-guidepopup-text {
  @include avenir-regular();
  font-size: rem(18px);
}

@mixin fontsize-article-item-title {
  @include avenir-bold;
  font-size: rem(19px);
  line-height: 1.3;
}
@mixin fontsize-article-item-header {
  @include avenir-regular;
  font-size: rem(11px);
  line-height: 1.1;
}
@mixin fontsize-overview-item-text {
  @include avenir-regular;
  font-size: rem(14px);
}
@mixin fontsize-quote {
  @include avenir-regular;
  font-size: rem(41px);
}
@mixin fontsize-button {
  @include avenir-medium;
}
@mixin fontsize-button--tertiary {
  @include avenir-regular;
}
@mixin fontsize-timeline--title {
  @include avenir-medium;
}
@mixin fontsize-timeline--subtitle {
  @include avenir-medium;
  font-size: rem(10px);
  text-transform: uppercase;
}
@mixin fontsize-offertable--title {
  @include avenir-medium;
  font-size: rem(23px);
}
@mixin fontsize-offertable--readmore-label {
  @include avenir-regular;
  font-size: rem(12px);
}
@mixin fontsize-links--title {
  @include avenir-medium;
  font-size: rem(25px);
}

@mixin fontsize-employersearch-label {
  @include avenir-regular;
  font-size: rem(13px);
}

@mixin fontsize-themes-label {
  @include avenir-regular;
  font-size: rem(14px);
}
@mixin fontsize-customercontact-title {
  @include avenir-bold;
  font-size: rem(16px);
  line-height: 1;
}
@mixin fontsize-aov-notification-title {
  @include avenir-bold;
  font-size: rem(16px);
  line-height: 1;
}
@mixin fontsize-aov-notification-text {
  @include avenir-regular;
  font-size: rem(16px);
  line-height: 1.25;
}

@mixin fontsize-aov-result-title {
  @include avenir-bold;
  font-size: rem(20px);
  line-height: 1;
}
@mixin fontsize-aov-result-subtitle {
  @include avenir-regular;
  font-size: rem(20px);
  line-height: 1;
}
@mixin fontsize-aov-result-premie-value {
  @include avenir-bold;
}
@mixin fontsize-aov-vitality-selection-title {
  @include avenir-bold;
}

@mixin fontsize-modal-title {
  @include avenir-regular;
  font-size: rem(25px);
  line-height: 1;
}
@mixin fontsize-modal-subtitle {
  @include avenir-regular;
  font-size: rem(17px);
  line-height: 1;
}
@mixin fontsize-modal-content-title {
  @include avenir-bold;
  font-size: rem(17px);
  line-height: 1;
}

@mixin fontsize-modal-body {
  @include avenir-regular;
  font-size: rem(16px);
}

@mixin fontsize-highlighted-article-label {
  @include avenir-regular;
  font-size: rem(12px);
  line-height: 1;
}
@mixin avenir-regular {
  font-family: AvenirNextLTPro;
  font-weight: 400;
}
@mixin avenir-medium {
  font-family: AvenirNextLTPro;
  font-weight: 600;
}
@mixin avenir-bold {
  font-family: AvenirNextLTPro;
  font-weight: 700;
}

@mixin fontsize-subtitle {
  display: block;
  font-size: 0.71em;
  font-weight: 400;
  margin-bottom: 0.2em;

  strong,
  b {
    @include avenir-bold;
  }
}
